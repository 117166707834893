
import { EChartsOption, SeriesOption, init } from 'echarts';
import { PropType, defineComponent, nextTick, onActivated, onBeforeUnmount, onBeforeUpdate, onDeactivated, onMounted } from 'vue';
import resize from './mixins/resize';



export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'mixedChartsComponents'
    },
    width: {
      type: String,
      default: '200px',
      required: true
    },
    height: {
      type: String,
      default: '200px',
      required: true
    },
    title: {
      type: String,
      default: 'statistics',
    },
    // 横坐标
    xaxis: {
      type: Array as PropType<string[]>,
      required: true,
    },
    // 数据
    chartData: {
      type: Object as PropType<{ [k: string]: Partial<SeriesOption & { sortid: number, styletype: "InsideTopBar" | "TopBar" | "TopLine" }> }>,
      default: {},
    },
  },
  setup(props) {
    const chartdatadefault = {
      InsideTopBar: {
        name: 'female',
        type: 'bar',
        stack: 'total',
        barMaxWidth: 35,
        barGap: '10%',
        itemStyle: {
          color: 'rgba(255,144,128,1)',
          label: {
            show: true,
            textStyle: {
              color: '#fff'
            },
            position: 'insideTop',
            formatter(p: any) {
              return p.value > 0 ? p.value : ''
            }
          }
        },
        data: []
      },

      TopBar: {
        name: 'male',
        type: 'bar',
        stack: 'total',
        itemStyle: {
          color: 'rgba(0,191,183,1)',
          borderRadius: 0,
          label: {
            show: true,
            position: 'top',
            formatter(p: any) {
              return p.value > 0 ? p.value : ''
            }
          }
        },
        data: []
      },

      TopLine: {
        name: 'average',
        type: 'line',
        stack: 'total',
        symbolSize: 10,
        symbol: 'circle',
        itemStyle: {
          color: 'rgba(252,230,48,1)',
          borderRadius: 0,
          label: {
            show: true,
            position: 'top',
            formatter(p: any) {
              return p.value > 0 ? p.value : ''
            }
          }
        },
        data: []
      }
    };
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const initChart = () => {
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      const xData = Object.keys(props.chartData);
      xData.sort((a, b) => { return (props.chartData[a].sortid || 0) - (props.chartData[b].sortid || 0) });
      let i = 0;
      const seriesData = xData.map(v => {
        let styletype = props.chartData[v].styletype;
        if (styletype == null) {
          if (i == 0) {
            styletype = "InsideTopBar";
          }
          else if (i == xData.length - 1) {
            styletype = "TopLine";
          }
          else {
            styletype = "TopBar";
          }
        }
        const r = Object.assign(Object.assign({}, chartdatadefault[styletype] || {}), props.chartData[v]);
        r.name = v;
        i++;
        return r;
      });
      barChart.setOption({
        backgroundColor: '#344b58',
        title: {
          text: props.title,
          top: '20',
          textStyle: {
            color: '#fff',
            fontSize: 22
          },
          subtextStyle: {
            color: '#90979c',
            fontSize: 16
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '5%',
          right: '5%',
          borderWidth: 0,
          top: 150,
          bottom: 95,
          textStyle: {
            color: '#fff'
          }
        },
        legend: {
          x: '5%',
          top: '10%',
          textStyle: {
            color: '#90979c'
          },
          data: xData
        },
        xAxis: [{
          type: 'category',
          axisLine: {
            lineStyle: {
              color: '#90979c'
            }
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitArea: {
            show: false
          },
          axisLabel: {
            interval: 0

          },
          data: props.xaxis
        }],
        yAxis: [{
          type: 'value',
          splitLine: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#90979c'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0
          },
          splitArea: {
            show: false
          }
        }],
        dataZoom: [{
          show: true,
          xAxisIndex: [
            0
          ],
          bottom: 30,
          start: 10,
          end: 80,
          handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
          handleSize: '110%',
          handleStyle: {
            color: '#d3dee5'

          },
          textStyle: {
            color: '#fff'
          },
          borderColor: '#90979c'
        }, {
          type: 'inside',
          show: true,
          start: 1,
          end: 35
        }],
        series: seriesData
      } as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })
    onBeforeUpdate(() => {
      initChart()
    })
    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {
    }
  }
})
