<template>
    <div class="app-container">
        <div class="filter-container">
            <el-tag :key="`今日活跃：`" style="margin-right:15px;">
                {{ "今日活跃：" + todayPlayerOnline }}
            </el-tag>
            <el-tag :key="`累计注册：`" style="margin-right:15px;">
                {{ "累计注册：" + totalPlayerCount }}
            </el-tag>
            <el-date-picker v-model="starttime" type="datetime" format="YYYY-MM-DD HH:mm:ss" placeholder="Start time" />
            <el-date-picker v-model="endtime" type="datetime" format="YYYY-MM-DD HH:mm:ss" placeholder="End time" />

            <!-- 标签 -->
            <el-select v-model="listQuery.label" :placeholder="`显示方式`" class="filter-item" style="width: 130px"
                @change="handleChange">
                <el-option v-for="item in labelOptions" :key="item" :label="item" :value="item" />
            </el-select>

            <el-button v-waves class="filter-item" type="primary" icon="el-icon-search" @click="getDataList">
                {{ $t("table.search") }}
            </el-button>
            <el-button v-waves :loading="downloadLoading" class="filter-item" type="primary" icon="el-icon-download"
                @click="handleDownload">
                {{ $t("table.export") }}
            </el-button>
        </div>
        <div class="chart-container">
            <MixedChart height="80%" width="100%" :title="`活跃玩家`" :xaxis="xAxisOptions" :chart-data="xChartData" />
        </div>
    </div>
</template>
  
<script lang="ts">
import { DataGame_GMSearchDailyDataStatistic } from '@/apis/data_game';
import MixedChart from '@/components/charts/MixedChart.vue';
import { formatJson } from "@/utils";
import { exportJson2Excel } from "@/utils/excel";
import { TodayEnd, TodayStart } from "@/utils/timer";
import { SeriesOption } from 'echarts';
import { ElForm, ElMessage } from 'element-plus';
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
    components: {
        MixedChart,
    },
    setup() {
        const { t } = useI18n()
        const dataForm = ref<typeof ElForm>()
        const dataMap = reactive({
            time: Date,
            tableKey: 0,
            list: [] as ITServerZoneDailyDataStatisticItem[],
            total: 1,
            listLoading: true,
            todayPlayerOnline: 0,
            totalPlayerCount:0,
            starttime: TodayStart(),
            endtime: TodayEnd(),
            listQuery: {
                title: "",
                label: "Hour",
                sort: "+id"
            },
            xAxisOptions: [] as string[],
            xChartData: {} as { [k: string]: Partial<SeriesOption & { sortid: number }> },
            labelOptions: ["Hour", "Day", "Week", "Month"],
            downloadLoading: false,
        })

        const getDataList = async () => {
            dataMap.listLoading = true;
            dataMap.list = [];
            let msg = {} as IC2G_GMSearchDailyDataStatistic;
            msg.StartTime = Math.ceil(dataMap.starttime.getTime() / 1000);
            msg.EndTime = Math.ceil(dataMap.endtime.getTime() / 1000);
            let cbmsg = await DataGame_GMSearchDailyDataStatistic(msg);
            if (cbmsg?.Error !== 0) {
                ElMessage.error(`GetLogFail: ${cbmsg?.Message}`);
                return;
            }
            if (cbmsg.SearchResult) {
                for (let k of cbmsg.SearchResult) {
                    let record = JSON.parse(k) as ITServerZoneDailyDataStatisticItem;
                    record.Id = record._id;
                    dataMap.list.push(record);
                }
                dataMap.list.sort((a, b) => {
                    return a.Year * 365 + a.Month * 30 + a.Day - (b.Year * 365 + b.Month * 30 + b.Day);
                })
            }
            if (cbmsg.Message) {
                let recordToday = JSON.parse(cbmsg.Message) as ITServerZoneDailyDataStatisticItem;
                dataMap.todayPlayerOnline = recordToday.HoursPlayerOnline.reduce((a, b) => a + b, 0) || 0;
                dataMap.totalPlayerCount = recordToday.TotalPlayerCount || 0;
            }
            handleChange(dataMap.listQuery.label);
            dataMap.total = cbmsg.SearchCount;
            dataMap.listLoading = false;
        }
        const handleChange = (val: string) => {
            dataMap.xAxisOptions = [];
            dataMap.xChartData = {};
            let vdata = [] as number[];
            let vdatatotal = [] as number[];
            if (val === "Hour") {
                dataMap.list.forEach(v => {
                    v.HoursPlayerOnline.forEach((_v, index) => {
                        dataMap.xAxisOptions.push(`${v.Month}/${v.Day} ${index}时`);
                        vdata.push(_v);
                        vdatatotal.push(v.TotalPlayerCount || 0)
                    })
                })
            }
            else if (val === "Day") {
                dataMap.list.forEach(v => {
                    let daycount = 0;
                    v.HoursPlayerOnline.forEach((_v, index) => {
                        daycount += _v;
                    })
                    dataMap.xAxisOptions.push(`${v.Year}/${v.Month} ${v.Day}号`);
                    vdata.push(daycount);
                    vdatatotal.push(v.TotalPlayerCount || 0)
                })
            }
            else if (val === "Week") {
                let weekindex = 1;
                const weekcount = {} as { [k: string]: { count: number, total: number } };
                dataMap.list.forEach(v => {
                    if (v.Day < 8) {
                        weekindex = 1;
                    }
                    else if (v.Day < 15) {
                        weekindex = 2;
                    }
                    else if (v.Day < 22) {
                        weekindex = 3;
                    }
                    else {
                        weekindex = 4;
                    }
                    const Xkey = `${v.Year}/${v.Month} ${weekindex}周`;
                    weekcount[Xkey] = weekcount[Xkey] || 0;
                    weekcount[Xkey].total = Math.max(weekcount[Xkey].total, v.TotalPlayerCount || 0);
                    v.HoursPlayerOnline.forEach((_v, index) => {
                        weekcount[Xkey].count += _v;
                    });
                    if (dataMap.xAxisOptions.includes(Xkey) === false) {
                        dataMap.xAxisOptions.push(Xkey);
                    }
                })
                dataMap.xAxisOptions.forEach(v => {
                    vdata.push(weekcount[v].count);
                    vdatatotal.push(weekcount[v].total);
                })
            }
            else if (val === "Month") {
                const monthcount = {} as { [k: string]: { count: number, total: number } };
                dataMap.list.forEach(v => {
                    const Xkey = `${v.Year}-${v.Month}`;
                    monthcount[Xkey] = monthcount[Xkey] || { count: 0, total: 0 };
                    monthcount[Xkey].total = Math.max(monthcount[Xkey].total, v.TotalPlayerCount || 0);
                    v.HoursPlayerOnline.forEach((_v, index) => {
                        monthcount[Xkey].count += _v;
                    });
                    if (dataMap.xAxisOptions.includes(Xkey) === false) {
                        dataMap.xAxisOptions.push(Xkey);
                    }
                })
                dataMap.xAxisOptions.forEach(v => {
                    vdata.push(monthcount[v].count);
                    vdatatotal.push(monthcount[v].total);
                })
            }
            dataMap.xChartData["OnlinePlayer"] = {
                sortid: 1,
                data: vdata,
            }
            dataMap.xChartData["TotalPlayer"] = {
                sortid: 2,
                data: vdatatotal,
            }
        }
        const handleDownload = () => {
            dataMap.downloadLoading = true;
            const tHeader = ["Time", "Year", "Month", "Day", "ServerID", "HoursPlayerNew", "HoursPlayerOnline", "HoursBattleCount", "OrderIncome", "ShopSellItem"];
            const filterVal = ["Time", "Year", "Month", "Day", "ServerID", "HoursPlayerNew", "HoursPlayerOnline", "HoursBattleCount", "OrderIncome", "ShopSellItem"];
            const data = formatJson(filterVal, dataMap.list);
            exportJson2Excel(tHeader, data, "DataPlayerOnline");
            dataMap.downloadLoading = false;
        }

        onMounted(() => {
            getDataList()
        })

        return {
            t, ...toRefs(dataMap),
            dataForm,
            getDataList,
            handleChange,
            handleDownload
        }
    }
})

</script>
<style lang="scss" scoped>
.chart-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 84px);
}
</style>