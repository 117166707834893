/*
 * @Description: 游戏数据
 * @Author: ZY
 * @Date: 2020-12-28 14:40:50
 * @LastEditors: scy😊
 * @LastEditTime: 2021-01-20 10:17:09
 */
import https from '@/utils/https'
import { ContentType, Method } from 'axios-mapper'

export const DataGame_GMSearchDailyDataStatistic = (data: IC2G_GMSearchDailyDataStatistic) => {
    return https().request<IG2C_GMSearchLog>('/GMSearchDailyDataStatistic', Method.POST, data, ContentType.json)
}
